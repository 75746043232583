import React from 'react';
import Page from 'components/page';
import Footer from 'components/footer';

import useStyles from 'apputil/page-styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPortalExit } from '@fortawesome/pro-duotone-svg-icons';

import { Grid, Typography } from '@material-ui/core';

const SuspendedLogoutPage = () => {
  const styles = useStyles();

  return (
      <Page
        title="Logout"
        description={'Goodbye for now'}>
        <Grid spacing={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12} sm={10}>
            <Typography variant="h3" component="h1" className={styles.workingHeader}>
              Goodbye for now
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <div className={styles.fourFourContainer}>
              <FontAwesomeIcon icon={faPortalExit} className={styles.workingIcon}/>
            </div>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="h5" component="p" className={styles.workingMessage}>
             Hope to see you again soon
            </Typography>
          </Grid>
        </Grid>
        <Footer />
      </Page>
  );
};

export default SuspendedLogoutPage;
